/* Animations */
@keyframes InfoPop {
    0% {transform: scale(0.98); opacity:50%;}
    100% {transform: scale(1); opacity:100%;}
}

@keyframes FeaturePop {
    0% {transform: scale(0.95);}
    100% {transform: scale(1); opacity:100%;}
}

/* Home Page */
.HomeContent{
    font-family: 'Inter', sans-serif;
}

/* Info Bubble */
.LayoutContainer{  
    margin: auto;
    padding-top: 40px;
    width: 830px;
}

.InfoBubble{  
    background: #232526; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right bottom,rgba(255, 255, 255, 0.15),rgba(255, 255, 2s55, 0.05)); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right bottom,rgba(255, 255, 255, 0.15),rgba(255, 255, 255, 0.05)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    height: 260px;
    border-radius: 15px;
    animation: InfoPop 0.2s;
}

.InfoImg{
    margin: auto;
    width: 150px;
    padding-top: 55px;
}

.InfoColLeft{
    float: left;
    width: 60%;
}
  
.InfoColRight{
    float: right;
    width: 30%;
}

.InfoContent{
    margin-left: 50px;
    margin-top: 40px;
}

.AltBoldText{
    color: #B794F4;
    font-weight: bold;
    font-size: 18px;
}

.MainText{
    color: white;
    font-weight: bold;
    padding-top: 10px;
    font-size: 28px;
}

.AltText{
    padding-top: 10px;
    color: #9497B3;
    font-size: 17px;
    font-weight: 500;
}

/* Info Bubble Buttons */
.infoButtonContainer{
    display: flex;
    column-gap: 10px;
    padding-top: 30px;
}

.infoButton{
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 30px;
    font-weight: 600;
    transition: 0.15s;
}

.infoButton#InfoMainButton{
    background-color: #D6BCFA;
    color: #252632;
}

.infoButton#InfoMainButton:hover{
    background-color: rgb(193, 149, 254);
}

.infoButton#InfoAltButton{
    background-color: transparent;
    color: #da1414;
    border-style: solid;
    border-width: 1px;
}

.infoButton#InfoAltButton:hover{
    background-color: #d6bcfa27;

}

/* Features */
.InfoDividerHR hr{
    height: 5px;
    border: 0;
    border-top: 1px solid #6061673e;
    margin: auto;
    padding: 0;
    margin-top: 30px;
}

.FeatureTitleContainer{
    margin-top: 25px;
    margin-bottom: 20px;
}

.FeatureTitleText{
    text-align: center;
    color: #E4E4E5;
    font-size: 26px;
    font-weight: 500;
    animation: FeaturePop 0.2s forwards;
    animation-delay: 50ms;
    opacity: 0%;
    color: rgba(255, 255, 255, 0.901);
}

/* Feature Boxes */
.FeatureBubbleContainer{
    padding-top: 20px;
}

.FeatureBubble{  
    height: 250px;
    border-radius: 15px;
    animation: FeaturePop 0.2s forwards;
    opacity: 0%;
    width: 800px;
    margin: auto;
    color: white;
}

.FeatureBubble#first{
    animation-delay: 100ms;
}

.FeatureBubble#second{
    animation-delay: 150ms;
}

.FeatureBubble#third{
    animation-delay: 200ms;
}

.FeatureColLeft{
    float: left;
    width: 50%;
}
  
.FeatureColRight{
    float: right;
    width: 50%;
    text-align: right;
}

.FTextContainer{
    padding-top: 38px;
    text-align: left;
}

.FTextContainer#CL{
    padding-top: 46px;
}

.FTextContainer#GE{
    padding-top: 80px;
}

.FTitle{
    font-size: 21px;
    font-weight: bold;
}

.FSubTitle{
    font-size: 16px;
    color: rgba(255, 255, 255, 0.655);
}


.EndOfPageSpacer{
    height: 200px;
}

/* Phones */
@media only screen and (max-device-width: 435px) {
    .LayoutContainer{  
        margin: auto;
        padding-top: 40px;
        width: 95vw;
    }
    /* Info Bubble */
    .InfoImg{
        padding-top: 30px;
        margin-left: -30px;
        transform: scale(0.6);
    }
    .InfoContent{
        margin-left: 0px;
        margin-top: -20px;
        transform: scale(0.8);
    }
    .AltBoldText{
        margin-top: 20px;
    } 
    .MainText{
        padding-top: 10px;
        font-size: 20px;
        text-wrap: nowrap;
    }
    .AltText{
        padding-top: 10px;
        font-size: 16px;
    }
    .infoButton{
        text-wrap: nowrap;
        transform: scale(1.1);
    }
    .infoButtonContainer{
        column-gap: 30px;
    }
    .InfoBubble{  
        height: 220px;
    }
    /* Features */
    .FTitle{
        font-size: 18px;
        text-wrap: nowrap;
    }
    .FTitle#LongBoi{
        font-size: 14.5px;
    }
    .FSubTitle{
        font-size: 12px;
    }
    .FTextContainer{
        width: 170px;
        margin-left: -20px;
        transform: scale(1);
        margin-top: 5px;
    }
    .FTextContainer#CL{
        width: 170px;
        margin-left: 1px;
        margin-top: 5px;
    }
    .FTextContainer#GE{
        width: 180px;
        margin-left: -15px;
        transform: scale(1);
        margin-top: 0px;
    }
    .FeatureBubble{  
        width: 300px;
        height: 180px;
    }
    .FPic{
        transform: scale(0.55);
        margin-left: -15px;
        margin-top: 0px;
    }
    .FPic#CL{
        margin-left: -68px;
    }
    .FPic#GE{
        margin-left: -20px;
    }
    .FeatureBubbleContainer{
        margin-top: -30px;
    }
    .FeatureTitleContainer{
        margin-top: 10px;
    }
    .FeatureTitleText{
        font-size: 21px;
    }
    .EndOfPageSpacer{
        height: 100px;
    }
}